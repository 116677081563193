import requestCore from "@/core/requestCore";
const rQ = requestCore;
const state = {
    token: null,
    user: {},
};

const getters = {};

const mutations = {
    follow: (state) => {
        state.user.isFollow = false;
    },
    unfollow: (state) => {
        state.user.isFollow = false;
    },
    setOtherUserData: (state, data) => {
        state.user = data;
    },
    setCreatorDreamGoal(state, payload) {
        state.user.dream_goal = payload;
    },
};

const actions = {
    async setOtherUser({ commit }, id) {
        return await rQ.getData(
            `users/${id}`,
            ["user", "otherUser"],
            "setOtherUserData",
            commit,
            ``,
            {},
            false,
            true
        );
    },
    async setOtherUserBySlug({ commit }, slug) {
        return await rQ.getData(
            `${slug}`,
            ["user", "otherUser"],
            "setOtherUserData",
            commit,
            ``,
            {},
            false
        );
    },
    async sendUserTip({ commit }, model) {
        return await rQ.setData(
            "user/own",
            model,
            ["setting", "tipSendBtn"],
            "",
            commit
        );
    },

    async sendDreamGoalTip({ commit }, model) {
        return await rQ.setData(
            "creator/send-dream-goal-tip",
            model,
            ["setting", "dreamGoalTipSendBtn"],
            "setCreatorDreamGoal",
            commit,
            "",
            false
        );
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
