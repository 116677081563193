export default {
    delete: "Удалить",
    close: "Закрыть",
    preview_document: "Просмотреть документ",
    tip_menu: {
        send_button: "Отправить",
        type_send: "Отправить",
        type_request: "Запрос",
        your_message: "Ваше сообщение",
        your_want_to_buy: "Ваш сообщение",
    },
    static_links: {
        contact_support: "Связаться со службой поддержки",
    },
    cc_bill: {
        complaints: "Запрос на удаление",
    },
    dream_goal: {
        send_button: "Отправить"
    }
}
