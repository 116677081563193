export default {
    delete: "Delete",
    close: "Close",
    preview_document: "Preview Document",
    tip_menu: {
        send_button: "Send",
        type_send: "Send",
        type_request: "Request",
        your_message: "Your Message",
        your_want_to_buy: "Your Message",
    },
    static_links: {
        contact_support: "Contact Support",
    },
    cc_bill: {
        complaints: "Takedown Request",
    },
    dream_goal: {
        send_button: "Send"
    }
}
