import Vue from "vue";
import { requestCore } from "@/core/index";
import i18n from "@/i18n";

const rQ = requestCore;
const state = {
    token: null,
    emailVerified: false,
    user: {
        referrals: [],
        followers: {},
        subscribers: {},
        subscriptions: {},
        discovers: {},
        notification: [],
        tips: [],
        usersInBlacklist: [],
        blockedRegions: [],
        sessions: [],
        wallet: {},
        messages: [],
        bankTransfer: {},
        paxum: {},
        eWallet: {},
        yotiShareButton: {},
        subscription: {},
        directMessage: {},
        referralCodes: [],
        data: {},
        isStoryData: true,
        userCategories: [],
        internationalWireTransfer: {},
        cryptoWallet: {},
    },
    components: {
        isShowVerifyDialog: false,
    },
};

const getters = {
    nextStepVerificationRouteName(state) {
        const { verifyOndatoAt, verifyAgreementAt } = state.user.data;
        if (!verifyOndatoAt) {
            return "user.setting.creator.verifyOndato";
        }
        if (!verifyAgreementAt) {
            return "user.setting.creator.agreement";
        }
        return null;
    },
    authUserIsVerified(state) {
        return !!state.user.data.verified;
    },
    getUserCategories: state => state.user.userCategories,
};

const mutations = {
    UNFOLLOW(state, id) {
        Vue.delete(state.user.following, id);
        state.user.data.followingCount = state.user.following?.length
    },
    setUserData(state, user) {
        Vue.set(state.user, "data", user);
        localStorage.setItem("email_verified", user.email_verified);
        if (
            !!user.language &&
            localStorage.getItem("noodzly_lang") !== user.language
        ) {
            Vue.set(i18n, "locale", user.language);
            localStorage.setItem("noodzly_lang", user.language);
        }
    },
    setUserSubscribers(state, sub) {
        function removeDuplicates(arr, prop) {
            const idSet = new Set();
            return arr.filter(
                (item) => !idSet.has(item[prop]) && idSet.add(item[prop])
            );
        }

        Vue.set(state.user, "subscribers", removeDuplicates(sub.data));
    },
    setUserSubscriptions(state, subscriptions) {
        Vue.set(
            state.user,
            "subscriptions",
            subscriptions.data?.filter((item) => item.has_stories !== 0)
        );
    },
    setUserFollowers(state, followers) {
        Vue.set(state.user, "followers", followers.data);
    },
    setUserFollowing(state, following) {
        Vue.set(
            state.user,
            "following",
            following.data
        );
    },
    setUserDiscovers(state, discovers) {
        Vue.set(
            state.user,
            "discovers",
            discovers.data?.filter((item) => item.has_stories !== 0)
        );
    },
    appendUserDiscovers(state, discovers) {
        Vue.set(
            state.user,
            "discovers", [
            ...state.user.discovers,
            ...discovers.data?.filter((item) => item.has_stories !== 0),
          ]);
    },
    setNotification(state, notification) {
        state.user.notification = notification;
    },
    setReferrals(state, referrals) {
        state.user.referrals = referrals.referrals;
        state.user.referralCodes = referrals.referral_codes;
    },
    setTips(state, tips) {
        state.user.tips = tips;
    },
    setNotificationSetting(state, notification) {
        state.user.settingNotification = notification;
    },
    setBlockedUsers(state, users) {
        state.user.usersInBlacklist = users;
    },
    getBlockedRegions(state, region) {
        try {
            region = JSON.parse(region);
        } catch (e) {
            console.log(e);
        } finally {
            state.user.blockedRegions = region;
        }
    },
    setSessions(state, sessions) {
        state.user.sessions = sessions;
    },
    setWallet(state, wallet) {
        state.user.wallet = wallet;
    },
    setWalletHistory(state, history) {
        state.user.walletHistory = history;
    },
    setMessages(state, messages) {
        state.user.messages = messages.data.sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );
    },
    setChat(state, chat) {
        state.user.chat = chat;
    },
    setOwnToken(state, token) {
        state.token = token;
    },
    setBitsafe(state, payload) {
        state.user.bitsafe = payload;
    },
    setBankTransfer(state, payload) {
        state.user.bankTransfer = payload;
    },
    setInternationalWireTransfer(state, payload) {
        state.user.internationalWireTransfer = payload;
    },
    setCryptoWallet(state, payload) {
        state.user.cryptoWallet = payload;
    },
    setYotiShareButton(state, payload) {
        state.user.yotiShareButton = payload;
    },
    setSubscription(state, payload) {
        state.user.subscription = payload;
    },
    setPriceMessage(state, payload) {
        state.user.directMessage.price = payload;
    },
    setTurnOffMessage(state, payload) {
        state.user.directMessage.turnOff = payload;
    },
    showVerifyDialog(state) {
        state.components.isShowVerifyDialog = true;
    },
    hideVerifyDialog(state) {
        state.components.isShowVerifyDialog = false;
    },
    setUserUnreadMessages(state, payload) {
        state.user.data.unread_messages = payload.unread_messages;
    },
    setUserUnreadNotifications(state, payload) {
        state.user.data.unread_notifications = payload.unread_notifications;
    },
    setUserDisplayUpdatedSubscription(state, payload) {
        state.user.data.updatedSubscriptionPrice = payload;
    },
    setCategories(state, categories) {
        state.user.userCategories = categories;
    },
    deleteTip(state, id) {
        state.user.tips.data = state.user.tips.data.filter(tip => tip.id !== id);
    },
    setStremGoal(state, payload) {
        state.user.data.goals = payload;
    },
    setHolidayMood(state, payload) {
        state.user.data.on_holiday_mood = payload.on_holiday_mood;
    },
    setCreatorDreamGoal(state, payload) {
        state.user.data.dream_goal = payload;
    },
};

const actions = {
    async getOwnMainUserData({ commit }, user) {
        commit("setMainUnloadedData", "OwnMainUserData");
        commit("setUserData", user);
        commit("setMainLoadedData", "OwnMainUserData");
    },
    async getCurrentUserData({ commit }) {
        const user = await rQ.getData(
            "users/me",
            "",
            "setUserData",
            commit,
            "",
            {},
            false,
            true
        );
        commit("setUserData", user.data);

        return user;
    },
    async follow({ commit }, id) {
        return await rQ.setData(
            `followers/follow/${id}`,
            {},
            "followBtn",
            "",
            commit,
            {},
            false
        );
    },
    async unfollow({ commit }, id) {
        return await rQ.setData(
            `followers/unfollow/${id}`,
            {},
            "unfollowBtn",
            "",
            commit,
            {},
            false,
            true
        );
    },
    async subscribe({ commit }, { id, bundle }) {
        return await rQ.setData(
            `subscribers/subscribe/${id}`,
            { bundle },
            "subscribeBtn",
            "",
            commit,
            {},
            false
        );
    },
    async resubscribe({ commit }, id) {
        return await rQ.setData(
            `subscribers/resubscribe/${id}`,
            {},
            "subscribeBtn",
            "",
            commit,
            {},
            false
        );
    },
    async unsubscribe({ commit }, { id, reason }) {
        return await rQ.setData(
            `subscribers/unsubscribe/${id}`,
            { reason: reason },
            "unsubscribeBtn",
            "",
            commit,
            {},
            false
        );
    },
    async subscriptionCancel({ commit }, id ) {
        return await rQ.setData(
            `subscribers/subscription-cancel/${id}`,
            {},
            "unsubscribeBtn",
            "",
            commit,
            {},
            false
        );
    },
    async buyDirectMessage({ commit }, id) {
        return await rQ.setData(
            `chat/buy/${id}`,
            {},
            "subscribeBtn",
            "",
            commit,
            {},
            false
        );
    },
    async getOwnSubscribers({ commit }, params = {}) {
        return await requestCore.getData(
            "subscribers",
            "subs",
            "setUserSubscribers",
            commit,
            "https://run.mocky.io/v3/a7f9efdb-a342-41ca-830a-e6e76a45f18c",
            params,
            false
        );
    },
    async getOwnSubscriptions({ commit }, params = {}) {
        var section_params = JSON.parse(JSON.stringify(params.sections));
        if(section_params.includes('subscribed')){
            const response = await axios.get("/api/subscriptions", {
                params: params,
                hideLoader: true
            });
            commit("setUserSubscriptions", response.data);
        } else {
            var data = {
                data: [],
            }
            commit("setUserSubscriptions", data);
        }
    },
    async getOwnFollowers({ commit }, params = {}) {
        return await requestCore.getData(
            "followers",
            "OwnFollowers",
            "setUserFollowers",
            commit,
            "https://run.mocky.io/v3/64425a35-9a43-40de-b3ea-7112407b7706",
            params,
            false,
            true
        );
    },
    async getOwnFollowing({ commit }, params = {}) {
        var section_params = JSON.parse(JSON.stringify(params.sections));
        if(section_params.includes('following')){
            const response = await axios.get("/api/followings", {
                params: params,
                hideLoader: true
            });
            commit("setUserFollowing", response.data);
        } else {
            var data = {
                data: [],
            }
            commit("setUserFollowing", data);
        }
    },
    async getOwnDiscovers({ commit }, params = {}) {
        var section_params = JSON.parse(JSON.stringify(params.sections));
        if(section_params.includes('discover')){ 
            const response = await axios.get("/api/discovers", {
                params: params,
                hideLoader: true
            });
    
            state.user.isStoryData = response.data.data.length > 0;

            if (params.page === 1) {
                commit("setUserDiscovers", response.data);
            } else {
                commit('appendUserDiscovers', response.data);
            }
        } else {
            var data = {
                data: [],
            }
            commit("setUserDiscovers", data);
        }
    },
    async getUserFollowing({ commit }) {
        const response = await axios.get("/api/followings", { hideLoader: true });
        commit("setUserFollowing", response.data);
    },
    async getNotifications({ commit }) {
        return await requestCore.getData(
            "users/notifications",
            "OwnNotification",
            "setNotification",
            commit,
            "https://run.mocky.io/v3/ee44988e-070e-4006-8316-96ade108f821",
            {},
            false
        );
    },

    async setProfileInfo({ commit }, { model }) {
        return await rQ.setData(
            "users",
            model,
            "infoBtn",
            "",
            commit,
            "",
            false
        );
    },
    async sendSubscriptionData({ commit }, { model }) {
        return await rQ.setData(
            "account/settings/subscription",
            model,
            "",
            "",
            commit,
            {},
            false,
            true
        );
    },

    async uploadWelcomeAudioMessage({ commit }, { audioBlob, creatorId }) {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'voice-welcome-message.mp4');
        formData.append('creator_id', creatorId);
  
        try {
            const response = await axios.post('/api/account/settings/welcome-audio', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error uploading audio:', error);
            throw error;
        }
    },

    async removeWelcomeAudioMessage({ commit }, { subscriptionId, audioName, audioPath }) {
        try {
            const response = await axios.post('/api/account/settings/remove-audio', { 
                subscriptionId: subscriptionId,
                audioName:audioName,
                audioPath:audioPath,
            });
            return response.data;
        } catch (error) {
            console.error('Error uploading audio:', error);
            throw error;
        }
    },

    async sendTipsMenu({ commit }, { model }) {
        return await rQ.setData("users/tips", model, "", "", commit, {}, false, true);
    },
    async deleteTipsMenu({ commit }, id) {
        return axios.delete(`api/users/tips/delete/${id}`)
            .then((resp) => {
                if (resp) {
                    commit("deleteTip", id);
                    return true;
                }
                return false;
            })
            .catch(() => false);
    },
    async getTipsMenu({ commit }, id) {
        return await rQ.getData(
            `users/${id}/tips`,
            ["setting", "tips"],
            "setTips",
            commit,
            "",
            {},
            false,
            true
        );
    },
    async getUserById({ commit }, id) {
        return await rQ.getData(`users/${id}`, "", "", commit, "", {}, false);
    },
    async sendBitsafe({ commit }, { model }) {
        return await rQ.setData(
            "account/settings/withdraw/bitsafe",
            model,
            ["setting", "withdraw", "directBtn"],
            "",
            commit,
            {},
            false
        );
    },

    async getBitsafe({ commit }) {
        return await rQ.getData(
            "account/settings/withdraw/bitsafe",
            "",
            "setBitsafe",
            commit,
            "",
            {},
            false,
            true
        );
    },

    async sendBankTransfer({ commit }, { model }) {
        return await rQ.setData(
            "account/settings/withdraw/bank-transfer",
            model,
            ["setting", "withdraw", "directBtn"],
            "",
            commit,
            {},
            false
        );
    },

    async getBankTransfer({ commit }) {
        return await rQ.getData(
            "account/settings/withdraw/bank-transfer",
            "",
            "setBankTransfer",
            commit,
            "",
            {},
            false,
            true
        );
    },
    async getInternationalWireTransfer({ commit }) {
        return await rQ.getData(
            "account/settings/withdraw/international-wire-transfer",
            "",
            "setInternationalWireTransfer",
            commit,
            "",
            {},
            false,
            true
        );
    },
    async sendInternationalWireTransfer({ commit }, { model }) {
        return await rQ.setData(
            "account/settings/withdraw/international-wire-transfer",
            model,
            ["setting", "withdraw", "directBtn"],
            "",
            commit,
            {},
            false
        );
    },
    async getCryptoWallet({ commit }) {
        return await rQ.getData(
            "account/settings/withdraw/crypto-wallet",
            "",
            "setCryptoWallet",
            commit,
            "",
            {},
            false,
            false
        );
    },
    async sendCryptoWallet({ commit }, { model }) {
        return await rQ.setData(
            "account/settings/withdraw/crypto-wallet",
            model,
            ["setting", "withdraw", "directBtn"],
            "",
            commit,
            {},
            false,
            false
        );
    },

    async sendPasswordChange({ commit }, { model }) {
        return await rQ.patch(
            "account/settings/password",
            model,
            ["setting", "passwordChangeBtn"],
            commit
        );
        //return  await rQ.setData('user/own', model, ['setting', 'passwordChangeBtn'], '', commit)
    },
    async sendEmailChange({ commit }, { model }) {
        return await rQ.patch(
            "account/settings/email",
            model,
            ["setting", "emailChangeBtn"],
            commit
        );
        //return await rQ.setData('user/own', model, ['setting', 'emailChangeBtn'], '', commit)
    },
    async sendLanguage({ commit }, { model }) {
        return await rQ.patch(
            "account/settings/language",
            model,
            ["setting", "langChangeBtn"],
            commit
        );
    },
    async sendBlockedUser({ commit }, { id }) {
        return await rQ.setData(
            "users/block",
            { user_id: id },
            ["setting", "blockUser"],
            "",
            commit,
            "",
            false
        );
    },
    async sendUnblockedUser({ commit }, { id }) {
        return await rQ.setData(
            "users/unblock",
            { user_id: id },
            ["setting", "blockUser"],
            "",
            commit,
            "",
            false
        );
    },
    async sendReportUser({ commit }, { defendant_id, accusation }) {
        return await rQ.setData(
            "users/report",
            { defendant_id: defendant_id, accusation: accusation },
            ["setting", "blockUser"],
            "",
            commit,
            "",
            false
        );
    },
    async sendContact({ commit }, model) {
        return await rQ.setData(
            "account/settings/contact-us",
            model,
            ["setting", "contactUs"],
            "",
            commit,
            "",
            false
        );
    },

    async sendContactAsGuest({ commit }, model) {
        return await rQ.setData(
            "contact-us-as-guest",
            model,
            ["setting", "contactUsAsGuest"],
            "",
            commit,
            "",
            false
        );
    },

    async setNotification({ commit }) {
        return await rQ.getData(
            "user/own",
            ["setting", "notification"],
            "setNotificationSetting",
            commit,
            "https://run.mocky.io/v3/eba22b62-570f-4872-9068-9727e6732ccf"
        );
    },
    async sendNotification({ commit }, { model }) {
        return await rQ.setData(
            "users/settings/notification",
            model,
            ["setting", "notificationBtn"],
            "setUserData",
            commit,
            "",
            false
        );
    },

    async setBlockedUsers({ commit }) {
        return await rQ.getData(
            "users/blocked",
            ["setting", "blockedUsers"],
            "setBlockedUsers",
            commit,
            "https://run.mocky.io/v3/f6ff7604-477f-4a91-bca9-c9f7891a07d9",
            {},
            false
        );
    },
    async deleteBlockedUsers({ commit }, id) {
        //return await rQ.deleteData('users/unblock', {'user_id': id}, '', commit)
        return await rQ.setData(
            "users/unblock",
            { user_id: id },
            ["setting", "unBlock"],
            "",
            commit,
            "",
            false
        );
    },
    async deleteUser({ commit }, password) {
        return await rQ.deleteData(
            "users/auth/delete",
            { password: password },
            "",
            commit,
            "",
            false
        );
        //return await rQ.setData('users/unblock', {'user_id': id}, ['setting', 'unBlock'], '', commit, '', false);
    },
    async getBlockedRegions({ commit }) {
        return await rQ.getData(
            "users/settings/blocked-countries",
            ["setting", "blockedRegions"],
            "getBlockedRegions",
            commit,
            "https://run.mocky.io/v3/97fed8ab-3455-4da3-8315-c415ccc3bf5e",
            {},
            false
        );
    },
    async setBlockedRegions({ commit }, { model }) {
        return await rQ.setData(
            "users/settings/blocked-countries",
            model,
            ["setting", "blockedRegions"],
            "",
            commit,
            "",
            false
        );
    },

    async setSessions({ commit }) {
        return await rQ.getData(
            "account/sessions",
            ["setting", "sessions"],
            "setSessions",
            commit,
            "https://run.mocky.io/v3/3244627e-3956-44ef-8865-fdec85f44768",
            {},
            false
        );
    },
    async setWallet({ commit }, { from, to }) {
        return await rQ.getData(
            `user/own/${from}/${to}`,
            ["setting", "wallet"],
            "setWallet",
            commit,
            `https://run.mocky.io/v3/bb94d30b-a131-444e-b2d9-96a60c120651/${from}/${to}`
        );
    },
    async setWalletHistory({ commit }, { from, to, transactions_type }) {
        const url = `wallet/history`;
        const query = {
            ...(from && { from: from }),
            ...(to && { to: to }),
            ...(transactions_type && { transactions_type: transactions_type }),
        };
        return await rQ.setData(
            url,
            query,
            ["setting", "walletFilters"],
            "",
            commit,
            "",
            false,
            true
        );
    },
    async deleteCard({ commit }, id) {
        return await rQ.deleteData("user/own", { id }, "", commit);
    },
    async sendNewCard({ commit }, { model }) {
        return await rQ.setData(
            "user/own",
            model,
            ["setting", "newCardBtn"],
            "",
            commit
        );
    },
    async sendTopUpViaCard({ commit }, { model }) {
        return await rQ.setData(
            "wallet/top-up-balance/card",
            { priceAmount: model },
            ["setting", "topUpBtn"],
            "",
            commit,
            "",
            false,
            true
        );
    },
    async sendTopUpViaCrypto({ commit }, { model }) {
        return await rQ.setData(
            "wallet/top-up-balance/crypto",
            { priceAmount: model },
            ["setting", "topUpBtn"],
            "",
            commit,
            "",
            false,
            true
        );
    },

    async sendTopUpViaPaypal({ commit }, { model }) {
        return await rQ.setData(
            "wallet/top-up-balance/paypal",
            { priceAmount: model },
            ["setting", "topUpBtn"],
            "",
            commit,
            "",
            false,
            true
        );
    },

    async capturePaypalPayment({ commit }, { orderId }) {
        return await rQ.setData(
            "wallet/paypal/capture-order",
            { orderId: orderId },
            ["setting", "topUpBtn"],
            "",
            commit,
            "",
            false,
            true
        );
    },

    setPriceMessage({ commit }, { model }) {
        return rQ.setData(
            "messages/set-price",
            model,
            "",
            "",
            commit,
            {},
            false,
            true
        );
    },
    setTurnOffMessage({ commit }, { model }) {
        return rQ.setData(
            "messages/set-turn-off",
            model,
            "",
            "",
            commit,
            {},
            false,
            true
        );
    },
    setMessages({ commit }, search = false) {
        let params = {};
        if (search) {
            params = {
                search: search,
            };
        }
        return rQ.getData(
            `chats`,
            ["setting", "messages"],
            "setMessages",
            commit,
            "",
            params,
            false,
            true
        );
    },
    async deleteMessage({ commit }, id) {
        return await rQ.deleteData("chat/" + id, {}, "", commit, "", false);
    },
    async setChat({ commit }, params) {
        return await rQ.getData(
            params.user_id
                ? `messages/user/${params.user_id}`
                : `messages/chat/${params.chat_id}`,
            ["setting", "chat"],
            "setChat",
            commit,
            `https://run.mocky.io/v3/17d929a9-362c-4276-b0a6-e25a9467c2f0`,
            {},
            false
        );
    },
    async sendTipMessage({ commit }, model) {
        return await rQ.setData(
            "messages/send-tip/creator",
            model,
            ["setting", "sendBtnMsg"],
            "",
            commit,
            "",
            false
        );
    },
    async sendTipMessageToBroadcast({ commit }, model) {
        return await rQ.setData(
            "messages/send-tip/broadcast-creator",
            model,
            ["setting", "sendBtnMsg"],
            "",
            commit,
            "",
            false
        );
    },
    async sendMessage({ commit }, model) {
        return await rQ.setData(
            "messages/send/creator",
            model,
            ["setting", "sendBtnMsg"],
            "",
            commit,
            "",
            false
        );
    },

    async pinMessage({ commit }, { message_id, chat_id }) {
        return await rQ.setData(
            "messages/broadcast/pin-message",
            { message_id: message_id, chat_id: chat_id },
            "",
            "",
            commit,
            "",
            false,
            false
        );
    },

    async removePinMessage({ commit }, chat_id) {
        return await rQ.setData(
            "messages/broadcast/unpin-message",
            chat_id,
            "",
            "",
            commit,
            "",
            false,
            false
        );
    },

    async readMessages({ commit }, model) {
        const response = await rQ.setData(
            "messages/read",
            model,
            "",
            "",
            commit,
            "",
            false,
            true
        );
        commit("setUserUnreadMessages", response);
        return response;
    },
    async readNotifications({ commit }) {
        const response = await rQ.setData(
            "users/notifications/read",
            {},
            "",
            "",
            commit,
            "",
            false,
            true
        );
        commit("setUserUnreadNotifications", response);
        return response;
    },
    async unreadMessages({ commit }) {
        const response = await rQ.getData(
            "messages/unread",
            "",
            "",
            commit,
            "",
            {},
            false,
            true
        );
        commit("setUserUnreadMessages", response);
        return response;
    },
    async unreadNotifications({ commit }) {
        const response = await rQ.getData(
            "users/notifications/unread",
            "",
            "",
            commit,
            "",
            {},
            false,
            true
        );
        commit("setUserUnreadNotifications", response);
        return response;
    },
    async setSearch({ commit }, params) {
        return await rQ.getData(
            `search`,
            "",
            "",
            commit,
            `https://run.mocky.io/v3/17d929a9-362c-4276-b0a6-e25a9467c2f0`,
            params,
            false
        );
    },
    async getYotiShareButton({ commit }) {
        return await rQ.getData(
            "yoti",
            "",
            "setYotiShareButton",
            commit,
            "",
            {},
            false
        );
    },
    async sendYotiToken({ commit }, model) {
        return await rQ.setData(
            "account/verify",
            model,
            "",
            "",
            commit,
            {},
            false
        );
    },
    async sendForgotPassword({ commit }, model) {
        return await rQ.setData(
            "forgot-password",
            model,
            "",
            "",
            commit,
            {},
            false
        );
    },
    async AdminBlockUser({ commit }, id) {
        return await rQ.setData(
            "admin/block-user",
            { user_id: id },
            "",
            "",
            commit,
            {},
            false
        );
    },
    async AdminBlockStory({ commit }, id) {
        return await rQ.setData(
            "admin/block-story",
            { story_id: id },
            "",
            "",
            commit,
            {},
            false
        );
    },
    async AdminRestrictStreams({ commit }, id) {
        return await rQ.setData(
            "admin/restrict-streams",
            { user_id: id },
            "",
            "",
            commit,
            {},
            false
        );
    },
    async AdminRefundStreamTickets({ commit }, { stream_id, viewer_id }) {
        return await rQ.setData(
            `admin/stream/${stream_id}/viewer/${viewer_id}/refund-ticket`,
            {},
            "",
            "",
            commit,
            {},
            false
        );
    },
    async AdminDocumentExpired({ commit }, id) {
        return await rQ.setData(
            `admin/users/${id}/document-expired`,
            {},
            "",
            "",
            commit,
            {},
            false
        );
    },
    async AdminDeleteUser({ commit }, id) {
        return await rQ.deleteData(
            `admin/users/${id}`,
            {},
            "",
            "",
            commit,
            {},
            false
        );
    },
    async EmailVerify({ commit }, params = null) {
        if (!params) {
            return await rQ.getData(
                "email-verify",
                "",
                "",
                commit,
                "",
                {},
                false
            );
        }
        const url = `email-verify/${params.id}/${params.hash}`;
        const query = {
            expires: params.expires,
            signature: params.signature,
        };
        return await rQ.getData(url, "", "", commit, "", query, false);
    },
    async getSubscription({ commit }) {
        return await rQ.getData(
            "account/settings/subscription",
            "",
            "setSubscription",
            commit,
            "",
            {},
            false,
            true
        );
    },
    async getReferrals({ commit }) {
        return await rQ.getData(
            "account/settings/referrals",
            ["settings", "referrals"],
            "setReferrals",
            commit,
            "",
            {},
            false,
            true
        );
    },
    async autocompleteUser(
        { commit },
        { query, except_users } = { query: "", except_users: [] }
    ) {
        return await rQ.setData(
            "users/autocomplete",
            { query, except_users },
            "",
            "",
            commit,
            "",
            false
        );
    },
    async isVerifyUser({ commit, state }) {
        const user = state.user ?? false;
        const verify = user.data.verify_at ?? false;
        if (verify) {
            commit("hideVerifyDialog");
        } else {
            commit("showVerifyDialog");
        }

        return !!verify;
    },
    async uploadAudioMessage({ commit }, { audioBlob, receiverId }) {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'voice-message.mp4');
        formData.append('receiver_id', receiverId);
  
        try {
            const response = await axios.post('/api/upload-audio', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error uploading audio:', error);
            throw error;
        }
    },
    async uploadAudioMessageToBroadcast({ commit }, {audioBlob, chatId }) {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'voice-message.mp4');
        formData.append('chat_id', chatId);
  
        try {
            const response = await axios.post('/api/broadcast/upload-audio', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error uploading audio:', error);
            throw error;
        }
    },
    async fetchUserCategories({ commit }) {
        return await rQ.getData(
            "categories",
            "",
            "setCategories",
            commit,
            "",
            {},
            false,
            false
        );
    },
    async switchAccount({ commit }, linkedUserId) {
        try {
            await axios.post('/api/account/switch-account', { linked_user_id: linkedUserId });

            window.location.reload();
        } catch (error) {
            throw error.response?.data?.error || error.message;
        }
    },
    async checkAddAccountPermission({ commit }){
        try {
            await axios.get('/api/account/check-add-account-permission');
        } catch (error) {
            throw error.response?.data?.error || error.message;
        }
    },
    async saveStreamGoals({ commit }, { id, model }){
        try {
            return await rQ.setData(
                `stream/${id}/add-goal`,
                model,
                "",
                "setStremGoal",
                commit,
                "",
                false
            );
        } catch (error) {
            throw error.response?.data?.error || error.message;
        }
    },

    async deleteStreamGoal({ commit }, id){
        try {
            return await rQ.deleteData(
                `stream/${id}/goal/delete`,
                {},
                "",
                "",
                commit,
                {},
                false
            );
        } catch (error) {
            throw error.response?.data?.error || error.message;
        }
    },

    async saveHolidayMood({ commit }, { model }) {
        try {
            return await rQ.setData(
                'creator/holiday-mood',
                model,
                "holidayMoodBtn",
                "setHolidayMood",
                commit,
                "",
                false
            );
        } catch (error) {
            throw error.response?.data?.error || error.message;
        }
    },

    async saveDreamGoal({ commit }, { model }) {
        return await rQ.setData(
            'creator/dream-goal',
            model,
            "DreamGoalBtn",
            "setCreatorDreamGoal",
            commit,
            "",
            false
        );
    },

    async getDreamGoal({ commit }, id) {
        return await rQ.getData(
            `creator/${id}/dream-goal`,
            "",
            "",
            commit,
            "",
            {},
            false,
            true
        );
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
