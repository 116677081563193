export default {
    delete: "Elimina",
    close: "Chiudi",
    preview_document: "Anteprima Documento",
    tip_menu: {
        send_button: "Invia",
        type_send: "Invia",
        type_request: "Richiesta",
        your_message: "Il Tuo Messaggio",
        your_want_to_buy: "Il Tuo Messaggio",
    },
    static_links: {
        contact_support: "Contatta il supporto",
    },
    cc_bill: {
        complaints: "Richiesta di rimozione",
    },
    dream_goal: {
        send_button: "Invia"
    }
}
